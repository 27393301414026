// extracted by mini-css-extract-plugin
export var column = "Grid__column__H8S4F";
export var flex = "Grid__flex__qNsIx";
export var flexColumn = "Grid__flexColumn__h12_p";
export var gap1 = "Grid__gap1__MSS96";
export var gap2 = "Grid__gap2__E9Vy_";
export var gap3 = "Grid__gap3__wzfRZ";
export var gap4 = "Grid__gap4__oYue8";
export var gap5 = "Grid__gap5__VJGnr";
export var grid = "Grid__grid__rtFUM";
export var row = "Grid__row__krMSy";