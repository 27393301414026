// extracted by mini-css-extract-plugin
export var column = "Label__column__QVhrx";
export var error = "Label__error__dQVUD";
export var flex = "Label__flex__Pn_cY";
export var flexColumn = "Label__flexColumn__ygq9B";
export var gap1 = "Label__gap1__hhEED";
export var gap2 = "Label__gap2__L4ULf";
export var gap3 = "Label__gap3__ExCJG";
export var gap4 = "Label__gap4__Ed_ke";
export var gap5 = "Label__gap5__fgqw3";
export var label = "Label__label__UzMhc";
export var required = "Label__required__KBp10";
export var row = "Label__row__QLtwi";