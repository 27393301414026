// extracted by mini-css-extract-plugin
export var column = "ErrorMessage__column__D1oVW";
export var error = "ErrorMessage__error__VAfBK";
export var flex = "ErrorMessage__flex__qEP_8";
export var flexColumn = "ErrorMessage__flexColumn__InDEE";
export var gap1 = "ErrorMessage__gap1__WuY6L";
export var gap2 = "ErrorMessage__gap2__NTXbp";
export var gap3 = "ErrorMessage__gap3__SouHH";
export var gap4 = "ErrorMessage__gap4__ke9Bv";
export var gap5 = "ErrorMessage__gap5__CezfZ";
export var row = "ErrorMessage__row__IKmHl";