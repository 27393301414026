// extracted by mini-css-extract-plugin
export var column = "BlogPostCategoryEdit__column__HjhKX";
export var container = "BlogPostCategoryEdit__container__DenKG";
export var flex = "BlogPostCategoryEdit__flex__fY9ZI";
export var flexColumn = "BlogPostCategoryEdit__flexColumn__CMV_d";
export var gap1 = "BlogPostCategoryEdit__gap1__YVP2X";
export var gap2 = "BlogPostCategoryEdit__gap2__a1UK2";
export var gap3 = "BlogPostCategoryEdit__gap3__n7yQX";
export var gap4 = "BlogPostCategoryEdit__gap4__NVEr5";
export var gap5 = "BlogPostCategoryEdit__gap5__QUVSZ";
export var pageContainer = "BlogPostCategoryEdit__pageContainer__LYvqx";
export var pageTitle = "BlogPostCategoryEdit__pageTitle__dqus6";
export var row = "BlogPostCategoryEdit__row__HWS9V";